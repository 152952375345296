<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="vtd-banner solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">RTaW-Peagase</h1>
        <p data-caption-delay="100" class="wow fadeInRight">支持多种车载E/E架构的建模、自动化配置、精确时间的仿真</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
          <div>立即咨询</div>
        </div>
      </div>
    </div>
    <section class="solution-intros electric-solution-intro wow fadeInLeft">
      <div class="container">
        <div class="normal-title">产品介绍</div>
        <div class="all-intro">
          <el-row>
            <el-col :span="24">
              <p class="intro-detail">
                RTaw-Peagase是法国国家信息与自动化研究所（INRIA）所属公司RealTime-at-Work（RTaW）开发的一款软件。
              </p>
              <p class="intro-detail">
                该软件支持多种车载E/E架构的建模、自动化配置、精确时间的仿真。支持从CAN、CANFD、LIN、FlexRay到Ethernet，再到TSN系统的仿真、分析、优化。
              </p>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections wow fadeInRight">
      <div class="container">
        <div class="normal-title">优势亮点</div>
        <div class="all-function">
          <ul class="priority-list">
            <li v-for="(item, index) in functionList" :key="index">{{ item.content }}</li>
          </ul>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections wow fadeInLeft">
      <div class="container">
        <div class="normal-title full-width">ADAS和高度自动驾驶 <span>能够实现感测和思考的能力，同时确保车辆的安全运行</span></div>
        <div class="all-function">
          <ul class="priority-list">
            <li><span class="width140">Ethernet TSN</span>1.建模、数据可视化、变型管理；2.仿真和worse-case 分析；3.自动化配置；4.标准载荷的假定分析；5.ARXML文件导入；<br>6.GUI、命令行、JAVA API。</li>
            <li><span class="width140">TSN Generative Design</span>1.Zero-Config TSN；2.拓扑压力测试；3.拓扑优化。</li>
            <li><span class="width140">其他</span>1.TSN HW Deployment；2.Software-Defined-Vehicle/Autosar；3.Trace-Inspector；4.Off-board Communications；<br>5.CAN/CANFD/CAN XL；6.LIN、FlexRay。</li>
          </ul>
        </div>
      </div>
    </section>
    <section class="solution-application wow fadeInRight">
      <div class="container">
        <div class="normal-title">应用领域</div>
        <div class="all-application">
          <div class="application-content">
            <div class="application-icon">
              <img src="../../assets/images/application-picture.svg" alt="">
            </div>
            <div class="application-text">
              <p>车载E/E网络架构</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="dyna-product-system-picture pv2x-system-pic wow fadeInLeft">
      <div class="container">
        <div class="normal-title">系统图片</div>
        <div class="all-system-picture">
          <div class="system-picture">
            <img src="../../assets/images/rtaw-system-image.png" alt="">
          </div>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
    </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue";
import mainFooter from "@/components/MainFooter.vue";
import "@/style/vtd.less"
import "@/style/vector/cast.less"
import "@/style/solution.less"
import bzInfo from "@/components/BzInfo.vue";

export default {
  name: "RTaW",
  components: {
    mainFooter,
    mainNav,
    bzInfo
  },
  data() {
    return {
      activeIndex: '3-4',
      functionList: [
        {
          id: 1,
          title: "建模",
          content: "通过文件或API接口描述网络和通讯需求"
        },
        {
          id: 2,
          title: "配置",
          content: "自动配置TSN参数，包括优先级、整型器、流量调度、帧抢占等；且支持CAN(FD、XL)、LIN、T1S、FlexRay"
        },
        {
          id: 3,
          title: "分析",
          content: "通过网络演算的worst-case分析，验证硬实时的时间约束"
        },
        {
          id: 4,
          title: "仿真",
          content: "用精确的时序仿真验证复杂的时序约束"
        },
        {
          id: 5,
          title: "可视化",
          content: "通过跨链路或网络的数据传输甘特图去理解网络的定时行为"
        },
        {
          id: 5,
          title: "系统级任务调度",
          content: "使用Software-Defined-X模块进行系统级/任务建模和性能分析"
        }
      ],
      swiperOption: {
        spaceBetween: 20, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 4, // 一屏幕显示几个? 数字或者默认  auto 自动。
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted() {
    document.title = "RTaW-Peagase - 产品中心 - 东信创智"
    new this.$wow.WOW().init()
  },
  methods: {
    linkUrl(url) {
      window.open(url, '_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>
